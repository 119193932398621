import { Module, Bloc, Theme } from "../models/module.model";
import {
  BlocDataFragment,
  BookmarkStatus,
  CategoryDataFragment,
  Category as CategoryGql,
  Lesson as LessonGql,
  Quiz as QuizGql,
  Status,
  ThemeDataFragment,
} from "../graphql/index";
import {
  Activity,
  ActivityType,
  ActivityPath,
  Quiz,
  Type,
} from "../models/activity.model";
import {
  getLastBookmark,
  isQuiz,
  mapActivity,
} from "../services/activity.service";
import { CONST } from "../config/constant";
import { getStandaloneApolloClient } from "./apollo";
import { gql } from "@apollo/client";
import { getTimestamp } from "../helpers/date.helper";
import { canAccessModule, getIsSuperAdmin, getUserId } from "./user.service";

export const mapModule = (category: CategoryDataFragment): Module => {
  let recursiveActivities: ActivityPath[] = [];
  let nbActivities = 0;
  let estimatedTime = 0;
  let confirmedTime = 0;
  let isStarted = false;
  let expertOpinion: string | undefined;
  let monthFolder: string | undefined;
  let blocs: Bloc[] = [];

  // Handle expert opinion
  if (!!category.briefs?.find((brief) => brief?.title === "Avis de l'expert")) {
    expertOpinion =
      category.briefs.find((brief) => brief?.title === "Avis de l'expert")
        ?.html ?? undefined;
  }

  if (!!category.briefs?.find((brief) => brief?.title === "Dossier du mois")) {
    monthFolder =
      category.briefs?.find((brief) => brief?.title === "Dossier du mois")
        ?.html ?? undefined;
  }

  for (let c of category.childrenDataV2 ?? []) {
    if (!c) continue;

    const bloc = mapBloc(c);
    nbActivities += bloc.nbActivities;
    estimatedTime += bloc.estimatedTime;
    confirmedTime += bloc.confirmedTime;
    isStarted = isStarted || bloc.isStarted;

    // // Handle dossier du mois
    // if (
    //   BlocSlug.EnvironnementLegal !== bloc.slug &&
    //   BlocSlug.PratiqueComptable !== bloc.slug
    // ) {
    //   monthFolder = bloc.briefs?.find(brief => brief?.title === "Dossier du mois")?.html ?? undefined;
    // }

    if (getIsSuperAdmin() || bloc.nbActivities > 0) {
      recursiveActivities = [
        ...recursiveActivities,
        ...bloc.recursiveActivities,
      ];
      blocs.push(bloc);
    }
  }

  blocs = blocs.sort((a, b) => (a.slug < b.slug ? 1 : -1));

  return {
    ...category,
    title:
      category.status === Status.Draft
        ? "[BROUILLON] " + category.title
        : category.title,
    recursiveActivities: recursiveActivities,
    nbActivities: nbActivities,
    estimatedTime: estimatedTime,
    confirmedTime: confirmedTime,
    progress: estimatedTime
      ? Math.floor((confirmedTime / estimatedTime) * 100)
      : 0,
    blocs: blocs,
    expired: category.validityTimeRange
      ? Date.now() >
        new Date(category.validityTimeRange.end).getTime() + 3600 * 1000 * 24
      : false,
    approvalType: "L",
    expertOpinion: expertOpinion,
    monthFolder: monthFolder,
    file:
      category.assetLinks?.length && category.assetLinks[0]?.media?.okulusId
        ? `${CONST.OKULUS_URL}/${category.assetLinks[0]?.media?.okulusId}`
        : undefined,
    isStarted: isStarted,
    canAccess: canAccessModule(category._id),
  };
};

export const mapBloc = (category: BlocDataFragment): Bloc => {
  const themes: Theme[] = [];
  const themeList = category.childrenDataV2 ?? [];
  const recursiveActivities: ActivityPath[] = [];

  let starterQuiz: Quiz | undefined;
  let nbActivities = 0;
  let estimatedTime = 0;
  let confirmedTime = 0;
  let isStarted = false;

  for (const c of themeList) {
    if (!c) continue;

    const theme = mapTheme(c);
    nbActivities += theme.nbActivities;
    estimatedTime += theme.estimatedTime;
    confirmedTime += theme.confirmedTime;
    isStarted = isStarted || theme.isStarted;

    if (!isStarted && !starterQuiz) {
      starterQuiz = theme.activitiesMapped.find(
        (a): a is Quiz => a.type === Type.QuizFinal && isQuiz(a)
      );
    }
    recursiveActivities.push(
      ...theme.activitiesMapped.map((a) => ({
        _id: a._id,
        type: isQuiz(a) ? ActivityType.Quiz : ActivityType.Lesson,
        themeId: theme._id,
        blocId: category._id,
      }))
    );
    themes.push(theme);
  }

  // Check starter quiz
  const lastStarterQuizProgress = getLastBookmark(
    starterQuiz?.bookmark ?? [],
    true
  );

  return {
    ...category,
    themes: themes,
    starterQuiz:
      !isStarted && lastStarterQuizProgress?.status !== BookmarkStatus.Done
        ? starterQuiz
        : undefined,
    recursiveActivities: recursiveActivities,
    nbActivities: nbActivities,
    estimatedTime: estimatedTime,
    confirmedTime: confirmedTime,
    progress: estimatedTime
      ? Math.floor((confirmedTime / estimatedTime) * 100)
      : 0,
    isStarted: isStarted || !starterQuiz,
  };
};

export const mapTheme = (category: ThemeDataFragment): Theme => {
  // Map activities
  let activities: Activity[] = [];
  const quizzes: QuizGql[] = (category.quizzes ?? []) as QuizGql[];
  const lessons: LessonGql[] = (category.lessons ?? []) as LessonGql[];

  let estimatedTime = 0;
  let confirmedTime = 0;
  let isStarted = false;

  for (let activity of [...lessons, ...quizzes]) {
    if (!activity) continue;

    const activityMapped = mapActivity(activity);
    activities.push(activityMapped);
    estimatedTime += activityMapped.estimatedTime ?? 0;
    confirmedTime += activityMapped.confirmedTime;
    isStarted = isStarted || activityMapped.isStarted;
  }

  return {
    ...category,
    activitiesMapped: activities,
    nbActivities: activities.length,
    estimatedTime: estimatedTime,
    confirmedTime: confirmedTime,
    progress: estimatedTime
      ? Math.floor((confirmedTime / estimatedTime) * 100)
      : 0,
    isStarted: isStarted || confirmedTime > 0,
  };
};

export const sortModuleByValidity = (
  a: Module | CategoryGql,
  b: Module | CategoryGql,
  direction: number = -1
) => {
  if (a.validityTimeRange?.start && b.validityTimeRange?.start) {
    return a.validityTimeRange.start < b.validityTimeRange.start
      ? direction
      : direction;
  }
  return a.validityTimeRange?.start ? direction : direction;
};

export const checkModuleCompletion = async (
  module: Module
): Promise<boolean> => {
  if (
    module.progress === 100 &&
    (module.elapsedTime?.duration ?? 0) < module.estimatedTime
  ) {
    const now = getTimestamp(new Date());
    const client = await getStandaloneApolloClient();

    await client.mutate({
      mutation: gql`
        mutation moduleTime(
          $userID: String!
          $startTimestamp: Int!
          $endTimestamp: Int!
          $learningCategorie: String
          $limit: Int
        ) {
          timePost(
            userID: $userID
            learningProduct: "ACTUA_CAC"
            startTimestamp: $startTimestamp
            endTimestamp: $endTimestamp
            learningCategorie: $learningCategorie
            limit: $limit
          ) {
            duration
          }
        }
      `,
      variables: {
        userID: getUserId(),
        startTimestamp: now,
        endTimestamp:
          now + (module.estimatedTime - (module.elapsedTime?.duration ?? 0)),
        learningCategorie: module.learningId.toString(),
        limit: module.estimatedTime,
      },
      errorPolicy: "ignore",
    });

    return true;
  }

  return false;
};
