// React
import Carousel from "nuka-carousel";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { Animate } from "react-simple-animate";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
// Material
import { Box, Button, Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// Libs
import Helmet from "react-helmet";
// Components
import ModuleExpiredBanner from "../../features/ModuleExpiredBanner/ModuleExpiredBanner";
import ModuleLockedBlocBanner from "../../features/ModuleLockedBlocBanner/ModuleLockedBlocBanner";
import ModuleTab from "../../features/ModuleTab/ModuleTab";
import ModuleTabs from "../../features/ModuleTabs/ModuleTabs";
import NoResult from "../../features/NoResult/NoResult";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
import ThemeCard from "../../features/ThemeCard/ThemeCard";
import StarterQuizCard from "../../features/Quiz/StarterQuizCard";
// Models
import {
  Module as ModuleCAC,
  Bloc,
  Theme as ThemeCAC,
} from "../../models/module.model";
import { Quiz } from "../../models/activity.model";
// Loader constant
import { CONST_LOADER } from "../../config/constantLoaders";
// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";
// Helper
import { getBlocTitle, getIndicatorColor } from "../../helpers/bloc.helper";
import { useSnackbar } from "notistack";
import { disableAutomaticScrolling } from "../../services/browser.service";
import { RESPONSIVE } from "../../config/responsive";
import { setSurveyChoice } from "../../store/survey/actions";
import {
  canAccessModule,
  getSurveySetting,
  getIsSuperAdmin,
} from "../../services/user.service";
import { BookmarkStatus, useModuleLazyQuery } from "../../graphql";
import {
  checkModuleCompletion,
  mapModule,
} from "../../services/module.service";
import { setCurrentModule } from "../../store/Module/actions";
// Services
import { getMostRecentModule } from "../../services/user.service";
import { setErrorToHandleError } from "../../store/errorHandler/actions";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        marginTop: -40,
      },
    },
    header: {
      height: "70px",
      position: "absolute",
      left: 0,
      width: "100%",
      zIndex: 100,
      backgroundColor: "#fcfcfd",
      boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)",
      [theme.breakpoints.up("md")]: {
        marginBottom: "17px",
      },
    },
    headerTitle: {
      color: "#006e49",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
    },
    container: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
    },
    wrapper: {
      width: "100%",
    },
    tabsContainer: {
      marginTop: 90,
    },
    tabs: {
      minWidth: "100%",
    },
    stickyTabs: {
      position: "sticky",
      top: 0,
      zIndex: 3,
      backgroundColor: "white",
    },
    expandableContainer: {
      paddingBottom: 40,
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(2),
      },
      /* ----------- iPhone X ----------- */
      /* Portrait */
      [RESPONSIVE.IPHONE_X.PORTRAIT]: {
        paddingBottom: 180,
      },
      /* ----------- iPhone 6+, 7+ and 8+ ----------- */
      /* Portrait */
      [RESPONSIVE.IPHONE_6_PLUS_AND_7_PLUS_AND_8_PLUS.PORTRAIT]: {
        paddingBottom: 180,
      },
    },
    carouselHeader: {
      backgroundColor: "#ffffff",
      position: "sticky",
      top: "0",
      zIndex: 3,
    },
    carouselMonthTab: {
      paddingTop: theme.spacing(1.5),
      boxShadow: "0px 3px 5px -2px rgba(128,128,128,0.7)",
    },
    indicator: {
      height: 3,
      width: "50%",
      marginTop: 15,
      marginLeft: "auto",
      marginRight: "auto",
    },
    noResult: {
      margin: theme.spacing(2),
    },
  })
);

/**
 * Route props
 */
interface IRouteInfo {
  moduleId: string;
}

interface IRouteModule
  extends RouteComponentProps<IRouteInfo, StaticContext, { fetch: boolean }> {}

/**
 * ModuleTabs props
 */
interface IModuleProps {
  isStickyTabs: boolean;
  setStickyTabs?: (value: boolean) => void;
}

/**
 * Module Component
 */
const Module = (props: IRouteModule & IModuleProps) => {
  const { match, isStickyTabs, setStickyTabs, location } = props;
  /** Classes  */
  const classes = useStyles();
  /** loading state */
  const [loading, setLoading] = useState<boolean>(true);
  /** Last module state */
  const [module, setModule] = useState<ModuleCAC>();
  const [starterQuiz, setStarterQuiz] = useState<Quiz>();
  const [showLockedNotification, setShowLockedNotification] = useState(false);
  /** moduleCac selector */
  const { selectedPath, currentModule, currentBlocId } = useSelector(
    (state: StoreState) => state.moduleCac
  );
  const [currentBlocIndex, setCurrentBlocIndex] = useState(0);
  /** carousel min height */
  const [carouselMinHeight, setCarouselMinHeight] = useState<number>(0);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /** UseDispatch */
  const dispatch = useDispatch();
  /** Graphql */
  const [getModule, { data, error }] = useModuleLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "network-only",
  });
  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Set page
   * @param page
   */
  const goToBloc = (blocIndex: number) => {
    setCurrentBlocIndex(blocIndex);

    /** update carousel's minimum height to prevent sticky bug */
    const activeSlide = document.getElementsByClassName("slide-visible")[0];
    if (activeSlide) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: 0,
      });
      setCarouselMinHeight(activeSlide.clientHeight);
    }
  };

  const nextBloc = () => {
    goToBloc(
      currentBlocIndex + 1 === module?.blocs.length ? 0 : currentBlocIndex + 1
    );
  };

  const previousBloc = () => {
    goToBloc(
      currentBlocIndex === 0
        ? (module?.blocs.length ?? 1) - 1
        : currentBlocIndex - 1
    );
  };

  const isThemeCardOpened = (
    blocId: string,
    themeId: string,
    themeIndex: number
  ): boolean =>
    blocId === selectedPath.blocId
      ? themeId === selectedPath.themeId
      : themeIndex === 0;

  /**
   * Send module validation notification
   */
  const sendModuleCompletionNotification = (title: string) => {
    // Display survey
    if (getSurveySetting() === undefined) {
      dispatch(setSurveyChoice(true));
    }

    // Notification
    enqueueSnackbar(
      `Votre attestation du module ${title} est disponible dans "Mes Attestations"`,
      {
        variant: "success",
        autoHideDuration: 12000,
      }
    );
  };

  /** Use effect */
  useEffect(() => {
    const mostRecentModuleId = getMostRecentModule();
    console.log("module recent", mostRecentModuleId);
    console.log("currentModule", currentModule);
    if (
      !match.params.moduleId &&
      (mostRecentModuleId !== currentModule?._id || !!location?.state?.fetch)
    ) {
      // If most recent module and fetch/refresh is needed
      getModule({
        variables: {
          idOrPath: mostRecentModuleId,
        },
      });
    } else if (
      match.params.moduleId &&
      (currentModule?._id !== match.params.moduleId || !!location?.state?.fetch)
    ) {
      // If specific module and fetch/refresh needed
      if (!canAccessModule(match.params.moduleId)) {
        dispatch(setErrorToHandleError(true, 401));
      } else {
        getModule({
          variables: {
            idOrPath: match.params.moduleId,
          },
        });
      }
    } else if (currentModule) {
      // Else display module in reducer
      setModule(currentModule);
      setLoading(false);
    }
  }, [match.url]);

  useEffect(() => {
    if (data?.category) {
      const moduleFetched = mapModule(data.category);
      console.log("moduleFetched", moduleFetched);
      dispatch(setCurrentModule(moduleFetched));
      setModule(moduleFetched);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (module) {
      const blocIndex = currentBlocId
        ? module.blocs.findIndex((b) => b._id === currentBlocId)
        : module.blocs.findIndex((b) => b._id === selectedPath.blocId);

      if (blocIndex >= 0 && blocIndex !== currentBlocIndex) {
        setCurrentBlocIndex(blocIndex);
      }
      setLoading(false);
      checkModuleCompletion(module).then(
        (isCompleted) =>
          isCompleted && sendModuleCompletionNotification(module.title ?? "")
      );
    }
  }, [module, selectedPath.blocId, currentBlocId]);

  /** Quiz starter use effet */
  useEffect(() => {
    if (module?.blocs[currentBlocIndex]) {
      setStarterQuiz(module.blocs[currentBlocIndex].starterQuiz);
    }
  }, [currentBlocIndex, module]);

  const isStarterQuizNotDone: boolean =
    starterQuiz?.bookmark?.every(
      (bookmark) =>
        bookmark?.status === BookmarkStatus.InProgress ||
        bookmark?.status === BookmarkStatus.NotStarted
    ) ?? false;

  /**
   *  this use Effect check if this child element opened and scroll to the last child Module open (activity or quizz)
   */
  useEffect(() => {
    setStickyTabs && setStickyTabs(false);

    setTimeout(() => {
      if (!loading && module) {
        if (selectedPath.activityId) {
          const blocOffsetTop =
            window.document.getElementById(selectedPath.activityId)
              ?.offsetTop || 0;
          const headerHeight =
            window.document.querySelector("header")?.offsetHeight || 0;
          dispatchScroll({
            type: "setScrollPosition",
            scrollPosition: blocOffsetTop + headerHeight,
          });
          disableAutomaticScrolling();
        }
      }
    }, 100);
  }, [!loading && module]);

  return (
    <div className={classes.root}>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>
          {match.params.moduleId && !module
            ? `Module`
            : match.params.moduleId && module
            ? module.title
            : `Module du mois`}{" "}
          - RF e-Learning CAC
        </title>
      </Helmet>
      {!loading &&
      module &&
      module.blocs.length > 0 &&
      module.blocs[currentBlocIndex] ? (
        <React.Fragment>
          {module.expired && <ModuleExpiredBanner />}
          {showLockedNotification && <ModuleLockedBlocBanner />}
          <Hidden lgUp={true}>
            <Grid
              container={true}
              direction="row"
              className={classes.carouselHeader}
            >
              <Box
                m={2}
                fontSize={20}
                textAlign="center"
                fontWeight="fontWeightBold"
                width="100%"
                className={classes.headerTitle}
              >
                Module : {module.title}
              </Box>
            </Grid>
            <Grid
              container={true}
              direction="row"
              className={`${classes.carouselHeader} ${classes.carouselMonthTab}`}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item={true}
                xs={2}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button size="small" onClick={previousBloc}>
                  <KeyboardArrowLeft />
                </Button>
              </Grid>
              <Grid item={true} xs={8}>
                <ModuleTab
                  title={getBlocTitle(module.blocs[currentBlocIndex].slug)}
                  nbActivities={module.blocs[currentBlocIndex].nbActivities}
                  percentage={module.blocs[currentBlocIndex].progress}
                  mobile={true}
                />
              </Grid>
              <Grid
                item={true}
                xs={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button size="small" onClick={nextBloc}>
                  <KeyboardArrowRight />
                </Button>
              </Grid>
              <div
                className={classes.indicator}
                style={getIndicatorColor(
                  module.blocs[currentBlocIndex].slug ?? ""
                )}
              />
            </Grid>
            <Carousel
              style={{ minHeight: carouselMinHeight }}
              width={"100%"}
              slidesToShow={1}
              slideIndex={currentBlocIndex}
              afterSlide={(slideIndex) => goToBloc(slideIndex)}
              withoutControls={true}
              wrapAround={true}
              frameOverflow={"visible"}
              heightMode={"current"}
            >
              {module.blocs.length
                ? module.blocs.map((bloc: Bloc) => (
                    <React.Fragment key={bloc._id}>
                      <Box ml={2} mr={2}>
                        {!!bloc.starterQuiz && (
                          <StarterQuizCard
                            blocSlug={bloc.slug}
                            quizActivity={bloc.starterQuiz}
                          />
                        )}
                        <Grid
                          container={true}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          alignContent="center"
                          className={classes.expandableContainer}
                        >
                          {bloc.themes.length ? (
                            bloc.themes.map(
                              (theme: ThemeCAC, index: number) => (
                                <ThemeCard
                                  onLockedClick={activateLockedNotification}
                                  key={theme._id}
                                  isOpen={isThemeCardOpened(
                                    bloc._id,
                                    theme._id,
                                    index
                                  )}
                                  theme={theme}
                                  expired={module.expired}
                                  locked={isStarterQuizNotDone}
                                  blocId={bloc._id}
                                />
                              )
                            )
                          ) : (
                            <div className={classes.noResult}>
                              <NoResult
                                titleCustom=""
                                subTitleCustom="Aucune activité disponible"
                              />
                            </div>
                          )}
                        </Grid>
                      </Box>
                    </React.Fragment>
                  ))
                : null}
            </Carousel>
          </Hidden>
          <Hidden mdDown={true}>
            {isStickyTabs ? (
              <Box className={classes.stickyTabs} id="tabsStickyContainer">
                <Animate
                  play={true}
                  duration={0.15}
                  start={{ opacity: 0 }}
                  end={{ opacity: 1 }}
                >
                  <ModuleTabs
                    blockList={module.blocs}
                    currentBlocIndex={currentBlocIndex}
                    isStickyTabs={isStickyTabs}
                    setCurrentBlocIndex={setCurrentBlocIndex}
                  />
                </Animate>
              </Box>
            ) : null}
            <Grid
              container={true}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.container}
            >
              <Grid item={true} xs={true} className={classes.wrapper}>
                <React.Fragment>
                  <Grid
                    container={true}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    className={classes.header}
                  >
                    <Grid item={true} className={classes.headerTitle}>
                      <Box fontSize={26} fontWeight="fontWeightBold">
                        Module : {module.title}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    id="tabsContainer"
                    className={classes.tabsContainer}
                  >
                    <div className={classes.tabs}>
                      <ModuleTabs
                        blockList={module.blocs}
                        currentBlocIndex={currentBlocIndex}
                        isStickyTabs={false}
                        setCurrentBlocIndex={setCurrentBlocIndex}
                      />
                    </div>
                  </Grid>
                  {!!starterQuiz && (
                    <StarterQuizCard
                      blocSlug={module.blocs[currentBlocIndex].slug}
                      quizActivity={starterQuiz}
                    />
                  )}
                  <Grid
                    container={true}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    alignContent="center"
                    className={classes.expandableContainer}
                  >
                    {module.blocs[currentBlocIndex].themes &&
                    module.blocs[currentBlocIndex].themes.length > 0 ? (
                      module.blocs[currentBlocIndex].themes.map(
                        (theme: ThemeCAC, index: number) =>
                          (getIsSuperAdmin() || theme.nbActivities > 0) && (
                            <ThemeCard
                              onLockedClick={activateLockedNotification}
                              key={theme._id}
                              locked={isStarterQuizNotDone}
                              isOpen={isThemeCardOpened(
                                module.blocs[currentBlocIndex]._id,
                                theme._id,
                                index
                              )}
                              theme={theme}
                              expired={module.expired}
                              blocId={module.blocs[currentBlocIndex]._id}
                            />
                          )
                      )
                    ) : (
                      <div className={classes.noResult}>
                        <NoResult
                          titleCustom=""
                          subTitleCustom="Aucune activité disponible"
                        />
                      </div>
                    )}
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
          </Hidden>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Hidden lgUp={true}>
            {module?.childrenDataV2?.length === 0 || module === undefined ? (
              <Grid
                container={true}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                alignContent="center"
                style={{ padding: 16 }}
              >
                <SkeletonLoader
                  height={880}
                  loader={CONST_LOADER.MONTH_MODULE_MOBILE}
                />
              </Grid>
            ) : (
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                Aucune activité disponible
              </Box>
            )}
          </Hidden>
          {module?.childrenDataV2?.length === 0 || module === undefined ? (
            <Hidden mdDown={true}>
              <Grid
                container={true}
                spacing={3}
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.container}
              >
                <Grid item={true} xs={true} className={classes.wrapper}>
                  <Grid
                    container={true}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.tabs}>
                      <SkeletonLoader
                        height={150}
                        loader={CONST_LOADER.MODULE_TABS}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    alignContent="center"
                    className={classes.expandableContainer}
                  >
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          ) : (
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              Aucune activité disponible
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(React.memo(Module));
